import React from 'react'
import styles from './Main.module.css'
import Link from './Link/Link';
import appStore_en from '../../assets/img/app_store_en.svg'
import appStore_ru from '../../assets/img/app_store_ru.svg'
import googlePlay_en from '../../assets/img/google_play_en.png'
import googlePlay_ru from '../../assets/img/google_play_ru.png'
import img from '../../assets/img/promo.jpg'
import { useTranslation } from 'react-i18next';

const Main = () => {
    const [t, i18n] = useTranslation();
    const isRu = i18n.language.includes('ru');
    return (
        <main className={styles.main}>
            <div className={styles.titles}>
                <h1>{t('Live wallpapers')}</h1>
                <h3>{t('The Best wallpapers')}</h3>
            </div>
            <div className={styles.linksContainer}>
                <div className={styles.links}>
                    <Link img={isRu ? appStore_ru : appStore_en} link={'https://apps.apple.com/us/app/alifeed-sales-and-discounts/id1348662911'} />
                    <Link img={isRu ? googlePlay_ru : googlePlay_en} type="google" link={'https://play.google.com/store/apps/details?id=cam.gadanie.hiromant'} />
                </div>
            </div>

        </main>
    )
}
export default Main;