import React, {useEffect} from 'react';
import './App.css';
import "./i18n/i18n";
import {useTranslation, withTranslation} from "react-i18next";
import Main from "./components/Main/Main";
import {Route,Switch} from "react-router-dom";
import Privacy from "./components/Privacy/Privacy";
import TermsOfUse from "./components/TermOfUse/TermsOfUse";
import styles from "./components/Main/Main.module.css";

const WithTranslationMain = withTranslation()(Main);
const NewMain = () => {
    const [t, i18n] = useTranslation();
    return <div>
        <WithTranslationMain/>
        <div className={styles.titles}>
            <h1>{t('Live wallpapers')}</h1>
            <h3>{t('The Best wallpapers')}</h3>
        </div>
    </div>
}
function App() {
  return (
      <div className="app">
        <div className="image">
          <Switch>
            <Route exact path={'/'} render={() => <WithTranslationMain/>} />
            <Route path={'/privacy'} render={() => <Privacy/>} />
            <Route path={'/termsofuse'} render={() => <TermsOfUse/>} />
          </Switch>
        </div>
      </div>
  );
}
export default App